.skill__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.skill__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem;
    border-radius: 2rem;
    transition: var(--transition);
}

.skill__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    color: grey;
    cursor: default;
}

.skill__container > div h3 {
    text-align: center;
    margin-bottom:  2rem;
    color: var(--color-primary);
}

.skill__block {
    width: 100%;
}

.skill__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.skill__details {
    display: flex;
    gap: 1rem;
}

.skill__details-icon {
    margin-top: 6px;
    color: var(--color-primary);
}

/*=============MEDIA QUERIES(MEDIUM DEVICES)==============*/
@media screen and (max-width: 1024px) {
    .skill__container {
        grid-template-columns: 1fr;
    }
    .skill__container > div {
        width: 80%;
        margin: 0 auto;
    }
    .skill__content {
        padding: 1rem;
    }
}

/*=============MEDIA QUERIES(SMALL DEVICES)==============*/
@media screen and (max-width: 600px) {
    .skill__container {
        grid-template-columns: 1fr;
    }
    .skill__container > div:hover {
        background: var(--color-bg-variant);
        color: white;
    }
    .skill__container {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
    .skill__container > div {
        width: 100%;
        margin: 0 auto;
        padding: 1rem;
    }
}