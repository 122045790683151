@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: white;
    --color-bg-variant: #44a0e1;
    --color-primary: #0d2b97;
    --color-primary-variant: #8bcff0;
    --color-black: black;
    --color-white: #fff;
    --color-light: rgba(255,255,255, 0.6);
    --color-text-dark: #054a91;
    --color-grey: rgb(163, 162, 162);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 100%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family:'Courier New', Courier, monospace;
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 1.7;
    /* background-image: url(../src/assets/bg-texture.png); */
}

/* ========= GENERAL STYLES ============*/
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 500;
}

h1 {
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 4rem;
}

section {
    margin-top: 8rem;
    /* height: 100hv; */
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-black);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom:3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-black);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.7rem 1.2rem;
    border-radius:0.4rem;
    cursor: pointer;
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-bg);
    color: var(--color-black);
    font-size: large;
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    text-align: center;
    color: var(--color-bg);
    padding: 0.7rem 1.2rem;
    border-radius:0.4rem;
    cursor: pointer;
    transition: var(--transition);
}

.btn-primary:hover {
    background: var(--color-bg);
    color: var(--color-black);
    border-color: transparent;
    border: 1px solid var(--color-primary);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/*=============MEDIA QUERIES(MEDIUM DEVICES)==============*/
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
    section {
        margin-top: 6rem;
    }
}

/*=============MEDIA QUERIES(SMALL DEVICES)==============*/
@media screen and (max-width: 600px) {
    .container {
        width: var( --container-width-sm);
    }
    section > h2 {
        margin-top: 2rem;
    }
}