.education__container {
    width: 80%;
    display: block;
    position:relative;
    left:10%;
    gap: 12%;

}

.education__options {
    display: flex;
    flex-direction: column;
    margin: 1.2rem;
    padding: 1rem;
    color:#aaa;
    background-color: var(--color-bg);
    border-radius: .5em;
    box-shadow: 0px 5px 20px 1px;
    animation: fadeInRight 1s;
    animation-duration: 1s;
    animation-fill-mode: both;
    span {
        color: black;
    }
}

.education__options .visible {
    opacity: 1;
    transform: translate(0, 0);
  }

.education__titles {
    display: block;
}

.education__title {
    display: inline-block;
}

.education__title .middle {
    width: 50rem;
}

.education_description {
    
}

.title {
    color: grey;
}

.education__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.education__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
    color: var(--color-white);
}


/* ===== TOGGLE ======= */
.toggle {
    display: block;
    
    span {
      &.expanded {
        animation: toggle-expand 1s ease-in-out forwards;
      }
    
      &.collapsed {
        animation: toggle-collapse 1s ease-in-out backwards;
      }
    }
  }
  
  @keyframes toggle-expand {
    0% { 
      transform: rotate(90deg);
    }
    100% { 
      transform: rotate(180deg);
    }
  }
  
  @keyframes toggle-collapse {
    0% { 
      transform: rotate(0deg);
    }
    100% { 
      transform: rotate(90deg);
    }
  }
.toggle_button {
    font-size: smaller;
    right: 10%;
}

/*=============MEDIA QUERIES(MEDIUM DEVICES)==============*/
@media screen and (max-width: 1024px) {
  .education__titles {
      font-size: medium;
  }
  .major {
    font-size: medium;
  }
}

/*=============MEDIA QUERIES(SMALL DEVICES)==============*/
@media screen and (max-width: 600px) {
  .education__titles {
      font-size:x-small;
  }
  .major {
    font-size: small;
  }
}